import {PhoneVerificationService} from './phone-verification.service';
import {Confetti} from '../confetti';

export class PhoneVerificationComponent {

  private step: 'phone-edit' | 'phone-validation' | 'phone-verified' = 'phone-edit';
  private language = 'he';
  private phoneCode: string = '';
  private readonly phrases = {
    en: {
      pageTitle: 'Contact Phone Verification',
      questionPhone: 'In order to finish the survey, I will send you a code to the mobile number (mobile number), So you can verify your mobile number.',
      questionCode: 'We sent you a text with a security code to {mobile} please enter the code and click Verify button below',
      phoneVerified: 'Your phone number has been successfully verified',
      buttonSendCode: 'Send code',
      buttonVerify: 'Verify',
      buttonChangePhone: 'Change phone',
      invalidCode: 'Invalid Code',
      phoneValidationError: 'Provide a valid phone number',
    },
    he: {
      pageTitle: 'אימות נייד',
      questionPhone: '\'על מנת לסיים את השאלון, אשלח אליך קוד למספר הנייד  (מספר נייד), עליך לאמת אותו.\'',
      questionCode: 'שלחתי אליך את קוד האימות. יש ללחוץ על כפתור האישור מטה ולהקליק את הקוד',
      phoneVerified: 'מספר הנייד שלך אומת, אפשר להמשיך.',
      buttonSendCode: 'שלח קוד',
      buttonVerify: 'אישור',
      buttonChangePhone: 'שנה נייד',
      invalidCode: 'הקוד שהוזן אינו תקין',
      phoneValidationError: 'יש להכניס מספר תקין',
    }
  };
  private readonly errorNode = this.createError(this.phrases[this.language].phoneValidationError);
  private readonly wrapper = document.createElement('div');

  private async onCodeRequest() {
    await this.service.requestVerificationCode(this.phone);
    this.step = 'phone-validation';
  }

  private async onPhoneValidate() {
    await this.service.verifyPhone(this.phone, this.phoneCode);
    this.step = 'phone-verified';
  }

  public constructor(private root: HTMLElement, private phone: string, private service: PhoneVerificationService) {
    this.wrapper.setAttribute('class', 'sv_main');
    root.appendChild(this.wrapper);
  }

  private clearWrapper() {
    this.wrapper.innerHTML = '';
  }

  private createError(errorText: string) {
    const error = document.createElement('div');
    error.setAttribute('class', 'sv_q_erbox sv_qstn_error_top');
    error.innerHTML = `<span>${errorText}</span>`;
    error.style.display = 'none';
    return error;
  }

  private setError(errorText: string, shown: boolean) {
    this.errorNode.querySelector('span').innerHTML = errorText;
    this.errorNode.style.display = shown ? 'block' : 'none';
  }

  private createPage(title: string, question: string, input: HTMLInputElement, button: HTMLButtonElement) {
    const container = document.createElement('div');
    container.setAttribute('class', 'sv_container');
    container.innerHTML = `
        <div class="sv_header">
          <h3><span>${title}</span></h3>
        </div>
        <div class="sv_body">
          <div class="sv_p_root">
            <div class="sv_row">
              <div class="sv_q sv_qstn">
              </div>
            </div>
          </div>
        </div>
    `;
    const questionNode = container.querySelector('.sv_q');
    const qTitle = document.createElement('h5');
    qTitle.setAttribute('class', 'sv_q_title');
    qTitle.innerHTML = `<span>${question}</span>`;
    questionNode.appendChild(qTitle);
    questionNode.appendChild(this.errorNode);
    questionNode.appendChild(input);
    container.querySelector('.sv_body').appendChild(button);
    return container;
  }

  private renderPhoneEdit() {
    const input = document.createElement('input');
    const button = document.createElement('button');

    input.setAttribute('value', this.phone);
    input.setAttribute('class', 'sv_q_text_root');
    input.setAttribute('type', 'tel');
    button.setAttribute('type', 'button');
    button.innerText = this.phrases[this.language].buttonSendCode;

    const container = this.createPage(this.phrases[this.language].pageTitle,
      this.phrases[this.language].questionPhone, input, button);
    this.wrapper.appendChild(container);

    const onButtonClick = async () => {
      this.phone = input.value;
      try {
        await this.onCodeRequest();
        this.render();
        button.removeEventListener('click', onButtonClick);
        this.setError('', false);
      } catch (e) {
        this.setError(this.phrases[this.language].phoneValidationError, true);
      }
    };
    button.addEventListener('click', onButtonClick);
  }

  private renderPhoneVerify() {
    const input = document.createElement('input');
    const button = document.createElement('button');

    input.setAttribute('class', 'sv_q_text_root');
    input.setAttribute('type', 'text');
    button.setAttribute('type', 'button');
    button.innerText = this.phrases[this.language].buttonVerify;

    const container = this.createPage(this.phrases[this.language].pageTitle,
      `${this.phrases[this.language].questionCode.replace('{mobile}', this.phone)}
      <br/>
      <small class="change-phone">${this.phrases[this.language].buttonChangePhone}</small>`, input, button);
    this.wrapper.appendChild(container);

    const onChangePhoneClick = () => {
      this.wrapper.querySelector('.change-phone').removeEventListener('click', onChangePhoneClick);
      this.phoneCode = '';
      this.step = 'phone-edit';
      this.render();
    };
    this.wrapper.querySelector('.change-phone').addEventListener('click', onChangePhoneClick);

    const onButtonClick = async () => {
      this.phoneCode = input.value;
      try {
        await this.onPhoneValidate();
        this.render();
        button.removeEventListener('click', onButtonClick);
        this.setError('', false);
      } catch (e) {
        this.setError(this.phrases[this.language].invalidCode, true);
      }
    };
    button.addEventListener('click', onButtonClick);
  }

  private renderComplete() {
    const container = document.createElement('div');
    container.setAttribute('class', 'sv_container');
    container.innerHTML = `
    <div class="sv_body sv_completed_page">
      <h1>${this.phrases[this.language].phoneVerified}</h1>
    </div>
    </div>
    `;
    this.wrapper.appendChild(container);
    new Confetti().show();
  }

  public render() {
    this.clearWrapper();
    if (this.step === 'phone-edit') {
      this.renderPhoneEdit();
    } else if (this.step === 'phone-validation') {
      this.renderPhoneVerify();
    } else {
      this.renderComplete();
    }
  }
}
