import {AppConfig} from './app-config';
import {handleRequest} from './smf-survey.handler';
import './styles.scss';

console.log('Env: ', AppConfig.ENV);
/*
* Entry point of survey request
*/

handleRequest(location.pathname);
