export class PhoneVerificationService {
  public isPhoneValid(phone: string) {
    return new RegExp(/^\d+$/).test(phone);
  }

  public async requestVerificationCode(forPhone: string) {
    if (!this.isPhoneValid(forPhone)) {
      throw new Error('invalid-phone');
    }
    return Promise.resolve();
  }

  public verifyPhone(phone: string, code: string) {
    if (!this.isPhoneValid(phone) || !code) {
      throw new Error('invalid-code');
    }
    return Promise.resolve();
  }
}

export class PhoneVerificationServiceTest extends PhoneVerificationService {
  public async requestVerificationCode(forPhone: string): Promise<void> {
    if (!this.isPhoneValid(forPhone)) {
      throw new Error('invalid-phone');
    }
    return Promise.resolve();
  }

  public async verifyPhone(phone: string, code: string): Promise<void> {
    if (!this.isPhoneValid(phone) || !code) {
      throw new Error('invalid-code');
    }
    return Promise.resolve();
  }
}
