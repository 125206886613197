import * as SurveyJS from 'survey-jquery';
import * as widgets from 'surveyjs-widgets';
import * as datepicker from 'jquery-ui/ui/widgets/datepicker';

export class Widgets {
    static importAll() {
        // styles must be imported in styles.scss e.g. @import "~jquery-ui/themes/base/datepicker.css";
        this.importDatePicker();
        //TODO
    }

    static importDatePicker() {
        $.fn.extend(datepicker);
        widgets.jqueryuidatepicker(SurveyJS);
    };
}

