import {SurveyModel} from 'survey-jquery';
import {AppConfig} from './app-config';


export class SmfSurveyModel {
    constructor(public _id: string, public answers: object, public questions: object) {
    }
}

export class SmfSurveyService {

    public readonly API_ADMIN_SURVEYS_URL: string;
    public readonly API_ADMIN_RESULTS_URL: string;

    public readonly surveysUrl: string;
    public readonly activitiesUrl: string;

    public constructor(env: AppConfig = AppConfig.ENV) {
        this.surveysUrl = env.API_URL + '/public/surveys';
        this.activitiesUrl = env.API_URL + '/public/activities';

        this.API_ADMIN_RESULTS_URL = env.API_URL + '/public/results';
        this.API_ADMIN_SURVEYS_URL = env.API_URL + '/public/surveys/test';
    }

    public loadSurvey(refCode: string): JQuery.jqXHR {
        const url = this.surveysUrl + '/' + refCode;
        return this.getJson(url);
    }

    public saveClickActivity(surveyId: string, refCode: string): JQuery.jqXHR {
        const url = this.activitiesUrl + '/click/' + refCode;
        const data = {surveyId: surveyId};
        // fire and forget
        return this.putJson(url, JSON.stringify(data)); // .done((id) => {})
    }

    public saveAnswers(surveyId: string, refCode: string, model: SurveyModel, options: any): JQuery.jqXHR {
        const data = this.prepareAnswers(surveyId, refCode, model, options);
        const url = this.surveysUrl + '/' + refCode;
        return this.postJson(url, JSON.stringify(data));
    }

    public prepareAnswers(surveyId: string, refCode: string, model: SurveyModel, options: any): SmfSurveyModel {
        const locale = model.getLocale() ? model.getLocale() : 'default';
        const questions = {};

        // TODO should be tested more, also with additional language
        model.getAllQuestions().forEach(question => {
            const result = {
                text: question.hasTitle ? question['title'] : question['label'],
                type: question.getType()
            };
            questions[question.getValueName()] = result;
            if (question['choices']) {
                result['choices'] = {};
                for (let choice of question['choices']) {
                    if (choice.hasText) {
                        result['choices'][choice['value']] = choice['text'];
                    }
                }
                if (question['hasOther']) {
                    result['choices']['other'] = question['otherText']
                }
            }
            if (question['rows']) {
                result['rows'] = {};
                for (let choice of question['rows']) {
                    if (choice.hasText) {
                        result['rows'][choice['value']] = choice['text'];
                    }
                    if (choice.hasTitle) {
                        result['rows'][choice['value']] = choice['title'] || choice['label'];
                    }
                }
            }
            if (question['columns']) {
                result['columns'] = {};
                for (let choice of question['columns']) {
                    if (choice['text'] || choice['title'] || choice['label']) {
                        result['columns'][choice['value']] = choice['text'] || choice['title'] || choice['label'];
                    }
                }
            }

            if (question['items']) {
                result['items'] = {};
                for (let choice of question['items']) {
                    if (choice['text'] || choice['title'] || choice['label']) {
                        result['items'][choice['name']] = choice['text'] || choice['title'] || choice['label'];
                    }
                }
            }
        });
        const answers = this.sanitizeEmailAndPhone(model.data)
        return new SmfSurveyModel(surveyId, answers, questions);
    }

    sanitizeEmailAndPhone(data: any): any {
        for (const key in data) {
            if (key === 'leadEmail' || key === 'contactEmail' || key === 'leadPhone' || key === 'contactPhone') {
                if ((typeof data[key] === 'string') && data[key].length > 0) {
                    // retain only printable ascii characters
                    data[key] = data[key].replace(/[^\x20-\x7E]/g, '')
                }
            }
        }
        return data
    }

    public putJson(url: string, data: string): JQuery.jqXHR {
        const settings = {type: 'PUT', url: url, data: data, contentType: 'application/json'};
        return $.ajax(settings);
    }

    public postJson(url: string, data: string): JQuery.jqXHR {
        const settings = {type: 'POST', url: url, data: data, contentType: 'application/json'};
        return $.ajax(settings);
    }

    public getJson(url: string): JQuery.jqXHR {
        return $.getJSON(url);
    }
}
