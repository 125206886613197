import {AppConfig} from './app-config';
import {SmfHandlerSurveyComponent} from './smf-survey.component';

export interface SurveyRequest {
    payload: string;
}

export enum SurveyRequestActionType {
    view = 'view', test = 'test', run = 'run', error = 'error'
}

interface SurveyRequestAction {
    action: SurveyRequestActionType;
    handle(surveyRequest: SurveyRequest): void;
}

class RunSurvey implements SurveyRequestAction {
    private surveyRequest: SurveyHandler;
    action: SurveyRequestActionType;
    constructor(machine: SurveyHandler, action: SurveyRequestActionType) {
        this.surveyRequest = machine;
        this.action = action;
    }

    handle(surveyRequest: SurveyRequest): void {
        (new SmfHandlerSurveyComponent(surveyRequest.payload, this.action));
    }
}

class TestSurvey implements SurveyRequestAction {
    private surveyRequest: SurveyHandler;
    action: SurveyRequestActionType;
    constructor(machine: SurveyHandler, action: SurveyRequestActionType) {
        this.surveyRequest = machine;
        this.action = action;
    }

    handle(surveyRequest: SurveyRequest): void {
        new SmfHandlerSurveyComponent(surveyRequest.payload, this.action);
    }
}

class ViewSurvey implements SurveyRequestAction {
    private surveyRequest: SurveyHandler;
    action: SurveyRequestActionType;
    constructor(machine: SurveyHandler, action: SurveyRequestActionType) {
        this.surveyRequest = machine;
        this.action = action;
    }

    handle(surveyRequest: SurveyRequest): void {
        new SmfHandlerSurveyComponent(surveyRequest.payload, this.action);
    }
}

class ErrorSurvey implements SurveyRequestAction {
    private surveyRequest: SurveyHandler;
    action: SurveyRequestActionType;
    constructor(machine: SurveyHandler, action: SurveyRequestActionType) {
        this.surveyRequest = machine;
        this.action = action;
    }

    handle(surveyRequest: SurveyRequest): void {
        new SmfHandlerSurveyComponent(surveyRequest.payload, this.action);
    }
}

export class SurveyHandler implements SurveyRequestAction {

    private currentAction: SurveyRequestAction;

    public surveyRequestActionType: SurveyRequestActionType;

    constructor(surveyRequestActionType: SurveyRequestActionType) {
        this.surveyRequestActionType = surveyRequestActionType;

        switch (this.surveyRequestActionType) {
            case SurveyRequestActionType.run : {
                this.currentAction = new RunSurvey(this, SurveyRequestActionType.run);
                break;
            }
            case SurveyRequestActionType.test : {
                this.currentAction =  new TestSurvey(this, SurveyRequestActionType.test);
                break;
            }
            case SurveyRequestActionType.view : {
                this.currentAction = new ViewSurvey(this, SurveyRequestActionType.view);
                break;
            }
            default : {
                this.currentAction = new ErrorSurvey(this, SurveyRequestActionType.error);
                break;
            }
        }
    }

    public set action(value: SurveyRequestActionType) {
        console.log(`Current state is ${value}`);
        this.surveyRequestActionType = value;
    }

    public get action() {
        return this.surveyRequestActionType;
    }

    handle(surveyRequest: SurveyRequest): void {
        this.currentAction.handle(surveyRequest);
    }
}

export const handleRequest = (pathname: String) => {
    const regexp: RegExp = new RegExp('([^/]*)\\/*(.*)');
    // TODO export hardcoded escape path to env variable
    // DO NOT end escape path with slash, see regexp
    // const base = '/surveys';
    const base = AppConfig.ENV.BASE_URL;
    const path = pathname.startsWith(base) ? pathname.substr(base.length) : pathname;
    const urlMatch = path.match(regexp);
    if (!urlMatch) {
        new SurveyHandler(SurveyRequestActionType.error).handle(null);
    } else {
        new SurveyHandler(urlMatch[2] ? SurveyRequestActionType[urlMatch[2]] : SurveyRequestActionType.run)
            .handle({payload: urlMatch[1]});
    }
};