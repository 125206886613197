import {Model as Survey, SurveyModel} from 'survey-jquery';
import {Confetti} from './confetti';
import {SurveyRequestActionType} from './smf-survey.handler';
import {SmfSurveyService} from './smf-survey.service';
import {Widgets} from "./widgets";
import {PhoneVerificationComponent} from './phone-verification/phone-verification.component';
import {PhoneVerificationServiceTest} from './phone-verification/phone-verification.service';

Widgets.importDatePicker(); // or Widgets.importAll();

export interface SmfSurveyComponent {
    survey?: Survey;
    surveyContainer?: HTMLElement;
    surveyId?: any;
    surveyJson?: any;
    url?: string;
    surveyRequestActionType?;

    init: () => void;

    render?: (data: any) => void;

    save?: (model: SurveyModel, options: any) => void;

    markdownProcessor?: IMarkdownProcessor;
}

interface IMarkdownProcessor {
    processMarkdown(model: SurveyModel, options: { element: any, text: string, html: string })
}

class AgreementAcceptMarkdownProcessor implements IMarkdownProcessor {
    public processMarkdown(model: SurveyModel, options: { element: any, text: string, html: string }) {
        if (options.element.name == 'agreeCheckbox') {
            options.html = options.text;
        }
    }
}

abstract class SmfSurveyComponentUISettings {

    /// Languages where UI is RTL
    private rtlLocales = ['he'];

    public applyUI(surveyContainer: HTMLElement, model: any) {
        if (model.survey.locale && this.rtlLocales.indexOf(model.survey.locale) != -1) {
            surveyContainer.setAttribute('class', 'rtl');
        }
    }
}

export class SmfErrorComponent implements SmfSurveyComponent {
    surveyContainer: HTMLElement;

    constructor(refCode: String, surveyRequestActionType: SurveyRequestActionType) {
        this.init();
    }

    init() {
        this.surveyContainer = document.createElement('h4');
        // TODO discuss this, may be forward just to survey to be filled
        this.surveyContainer.innerText = 'SFM - survey!';
        document.body.appendChild(this.surveyContainer);
    }

}

export class SmfRunSurveyComponent extends SmfSurveyComponentUISettings implements SmfSurveyComponent {
    survey: Survey;
    surveyContainer: HTMLElement;
    surveyId: any;
    surveyJson: any;
    surveyRequestActionType;
    private readonly WHITE_SPACES_REGEX = new RegExp("^\\s+|\\s+$|\\s+(?=\\s)", "g");

    constructor(private service: SmfSurveyService, public refCode: string, surveyRequestActionType: SurveyRequestActionType, public markdownProcessor: IMarkdownProcessor) {
        super();
        this.surveyRequestActionType = surveyRequestActionType;
        this.init();
    }

    init() {
        // this.addDebugButton();
        this.surveyContainer = document.createElement('div');
        document.body.appendChild(this.surveyContainer);

        this.survey = new Survey();
        window["survey"] = this.survey;
        this.survey.onValueChanged.add((model, options) => {
            if (typeof options.value === 'string') {
                // TODO apply regex for remove all spaces > 1
                model.setValue(options.name, options.value.replace(this.WHITE_SPACES_REGEX, ""));
            }
        });
        this.survey.onComplete.add((model, options) => this.save(model, options));

        if (this.markdownProcessor) {
            this.survey.onTextMarkdown.add(this.markdownProcessor.processMarkdown);
        }

        this.service.loadSurvey(this.refCode).done((data) => {
            this.surveyId = data['_id'];
            const survey = Object.assign({locale: data['survey']['locale']}, data['survey']);
            this.surveyJson = survey
            this.service.saveClickActivity(this.surveyId, this.refCode); // no need to wait
            data['survey'] = survey
            this.render(data);
        });
    }

    render(data: any) {
        this.applyUI(this.surveyContainer, data);
        this.survey.setJsonObject(this.surveyJson);
        this.survey.render(this.surveyContainer);
    }

    save(model: SurveyModel, options: any) {
        this.service.saveAnswers(this.surveyId, this.refCode, model, options)
            .done((data, statusTest, jqXHR) => {
                if (jqXHR.status === 201) {
                    const location = jqXHR.getResponseHeader('Location')
                    if (location) {
                        window.location.replace(location)
                    }
                }
                new Confetti().show();
            });
    }

    addDebugButton() {
        const btn: HTMLButtonElement = document.createElement('button');
        btn.innerHTML = "Debug";
        btn.style.marginTop = '30px';
        btn.addEventListener("click", () => {
            const model = this.service.prepareAnswers(this.surveyId, this.refCode, this.survey, {});
            console.log(model);
        });
        document.body.appendChild(btn);
    }

}

export class SmfTestSurveyComponent extends SmfSurveyComponentUISettings implements SmfSurveyComponent {
    survey: Survey;
    surveyContainer: HTMLElement;
    surveyId: any;
    surveyJson: any;
    surveyRequestActionType;

    constructor(private service: SmfSurveyService, public refCode: String, surveyRequestActionType: SurveyRequestActionType, public markdownProcessor: IMarkdownProcessor) {
        super();
        this.surveyRequestActionType = surveyRequestActionType;
        this.init();
    }

    init() {
        this.surveyContainer = document.createElement('div');
        document.body.appendChild(this.surveyContainer);

        this.survey = new Survey();
        this.survey.onComplete.add((model, options) => this.save(model, options));

        // Serializer.addProperty("itemvalue", {name: "noEscape:boolean", default: false});
        if (this.markdownProcessor) {
            this.survey.onTextMarkdown.add(this.markdownProcessor.processMarkdown);
        }

        const url = this.service.API_ADMIN_SURVEYS_URL + '/' + this.refCode;
        const xhr = this.service.getJson(url);
        xhr.done((data) => {
            data['survey'] = Object.assign({locale: data['survey']['locale']}, data['survey']);
            this.render(data);
        });
    }

    render(data: any) {
        this.applyUI(this.surveyContainer, data);
        this.surveyId = data['_id'];
        this.surveyJson = data['survey'];
        this.survey.setJsonObject(this.surveyJson);
        this.survey.render(this.surveyContainer);
    }

    save(model: SurveyModel, options: any) {
        // new Confetti().show();
        const parent: HTMLElement = document.querySelector('body > div');
        parent.innerHTML = '';
        const data = this.service.prepareAnswers('', '', model, options);
        const node = new PhoneVerificationComponent(parent, data.answers['leadPhone'], new PhoneVerificationServiceTest());
        node.render();
    }

}

export class SmfViewResultSurveyComponent implements SmfSurveyComponent {
    survey: Survey;
    surveyContainer: HTMLElement;
    surveyId: any;
    surveyJson: any;

    surveyRequestActionType;

    constructor(private service: SmfSurveyService, public refCode: String, surveyRequestActionType: SurveyRequestActionType, public markdownProcessor: IMarkdownProcessor) {
        this.surveyRequestActionType = surveyRequestActionType;

        this.init();
    }

    init() {
        this.surveyContainer = document.createElement('div');
        document.body.appendChild(this.surveyContainer);

        this.survey = new Survey();

        if (this.markdownProcessor) {
            this.survey.onTextMarkdown.add(this.markdownProcessor.processMarkdown);
        }

        const url = this.service.API_ADMIN_RESULTS_URL + '/' + this.refCode;
        const xhr = this.service.getJson(url);
        xhr.done((result: any) => {
            const urlSurvey = this.service.API_ADMIN_SURVEYS_URL + '/' + result._refs.surveyId;
            const xhr = this.service.getJson(urlSurvey);
            xhr.done((survey: SurveyModel) => {
                let data = {};
                $.extend(true, data, result.answers, result.hiddenAnswers);
                survey.data = data;
                this.render(survey);
            });
        });
    }

    render(data: any) {
        this.surveyId = data['_id'];
        this.surveyJson = data['survey'];
        this.survey.setJsonObject(this.surveyJson);
        this.survey.data = data['data'];
        this.survey.mode = 'display';
        this.survey.isSinglePage = true;
        this.survey.render(this.surveyContainer);
    }

}

export class SmfHandlerSurveyComponent implements SmfSurveyComponent {
    surveyRequestActionType;
    currentComponent: SmfSurveyComponent;
    markdownProcessor: IMarkdownProcessor = new AgreementAcceptMarkdownProcessor();

    constructor(public refCode: string, surveyRequestActionType: SurveyRequestActionType) {
        this.surveyRequestActionType = surveyRequestActionType;

        const service = new SmfSurveyService();

        switch (this.surveyRequestActionType) {
            case SurveyRequestActionType.run : {
                this.currentComponent = new SmfRunSurveyComponent(service, this.refCode, SurveyRequestActionType.run, this.markdownProcessor);
                break;
            }
            case SurveyRequestActionType.test : {
                this.currentComponent = new SmfTestSurveyComponent(service, this.refCode, SurveyRequestActionType.test, this.markdownProcessor);
                break;
            }
            case SurveyRequestActionType.view : {
                this.currentComponent = new SmfViewResultSurveyComponent(service, this.refCode, SurveyRequestActionType.view, this.markdownProcessor);
                break;
            }
            default : {
                this.currentComponent = new SmfErrorComponent(this.refCode, SurveyRequestActionType.error);
                break;
            }
        }
    }

    init() {
        this.currentComponent.init();
    }

}
